const wallet = [
  {
    id: 20,
    name: "Coinbase Wallet",
    imagePath: "./wallet Images/coinbase.jpg",
  },
  { id: 68, name: "SafePal", imagePath: "./wallet Images/SafePal.jpeg" },
  { id: 84, name: "Trust Wallet", imagePath: "./wallet Images/trust.jpeg" },
  {
    id: 104,
    name: "Wallet Connect",
    imagePath: "./wallet Images/walletconnect.png",
  },
  { id: 54, name: "MetaMask", imagePath: "./wallet Images/metamask.jpeg" },
  { id: 100, name: "SaitaMask", imagePath: "./wallet Images/saitamask.png" },
  {
    id: 77,
    name: "Token Pocket",
    imagePath: "./wallet Images/token pocket.jpeg",
  },
  { id: 11, name: "Binance", imagePath: "./wallet Images/binance.png" },
  { id: 44, name: "imToken", imagePath: "./wallet Images/imtoken.jpeg" },
  { id: 14, name: "Blockchain", imagePath: "./wallet Images/blockchain.png" },
  { id: 42, name: "HashKey Me", imagePath: "./wallet Images/HashKeyMe.jpeg" },
  { id: 6, name: "Argent", imagePath: "./wallet Images/argent.jpeg" },
  { id: 92, name: "Walleth", imagePath: "./wallet Images/walletH.jpeg" },
  { id: 45, name: "Infinito", imagePath: "./wallet Images/infinito.jpeg" },
  {
    id: 15,
    name: "Bridge Wallet",
    imagePath: "./wallet Images/bridge wallet.jpeg",
  },
  {
    id: 76,
    name: "Terra Wallet",
    imagePath: "./wallet Images/Terra Wallet.jpg",
  },
  { id: 67, name: "RWallet", imagePath: "./wallet Images/RWallet.jpeg" },
  {
    id: 36,
    name: "Flare Wallet",
    imagePath: "./wallet Images/Flare Wallet.jpeg",
  },
  { id: 60, name: "Ownbit", imagePath: "./wallet Images/own bit.jpeg" },
  { id: 87, name: "Valora", imagePath: "./wallet Images/Valora.jpeg" },
  { id: 9, name: "Atomic", imagePath: "./wallet Images/atomic.jpeg" },
  { id: 7, name: "AT.Wallet", imagePath: "./wallet Images/AT.Wallet.jpeg" },
  { id: 90, name: "Vision", imagePath: "./wallet Images/Vision.jpeg" },
  {
    id: 86,
    name: "Unstoppable Wallet",
    imagePath: "./wallet Images/Unstoppable Wallet.jpeg",
  },
  { id: 2, name: "AAVE", imagePath: "./wallet Images/aave.jpg" },
  { id: 83, name: "TrustVault", imagePath: "./wallet Images/Trust Vault.jpeg" },
  {
    id: 85,
    name: "Trustee Wallet",
    imagePath: "./wallet Images/Trustee Wallet.jpeg",
  },
  {
    id: 23,
    name: "CoolWallet S",
    imagePath: "./wallet Images/CoolWallet S.jpeg",
  },
  {
    id: 40,
    name: "Guarda Wallet",
    imagePath: "./wallet Images/Guarda Wallet.jpeg",
  },
  { id: 64, name: "Portis", imagePath: "./wallet Images/portis.jpg" },
  {
    id: 50,
    name: "Ledger Live",
    imagePath: "./wallet Images/ledger live.jpeg",
  },
  {
    id: 43,
    name: "Huobi Wallet",
    imagePath: "./wallet Images/houbiwallet.jpeg",
  },
  { id: 18, name: "Coin98", imagePath: "./wallet Images/coin98.jpeg" },
  { id: 31, name: "Dok Wallet", imagePath: "./wallet Images/Dok Wallet.jpeg" },
  { id: 17, name: "CMORQ", imagePath: "./wallet Images/cmorq.jpeg" },
  { id: 66, name: "Rainbow", imagePath: "./wallet Images/rainbow.jpeg" },
  {
    id: 5,
    name: "AlphaWallet",
    imagePath: "./wallet Images/alpha wallet.jpeg",
  },
  {
    id: 55,
    name: "Midas Wallet",
    imagePath: "./wallet Images/Midas Wallet.jpeg",
  },
  { id: 49, name: "KyberSwap", imagePath: "./wallet Images/KyberSwap.jpeg" },
  { id: 28, name: "Defiat", imagePath: "./wallet Images/defiat.jpg" },
  { id: 58, name: "O3Wallet", imagePath: "./wallet Images/O3Wallet.jpeg" },
  { id: 37, name: "Fortmatic", imagePath: "./wallet Images/fortmatic.jpg" },
  {
    id: 25,
    name: "CYBAVO Wallet",
    imagePath: "./wallet Images/CY Bravo wallet.jpeg",
  },
  {
    id: 48,
    name: "KEYRING PRO",
    imagePath: "./wallet Images/KEYRING PRO.jpeg",
  },
  {
    id: 80,
    name: "Tongue Wallet",
    imagePath: "./wallet Images/Tongue Wallet.jpeg",
  },
  { id: 22, name: "CoinUs", imagePath: "./wallet Images/CoinUS.jpeg" },
  { id: 29, name: "Dharma", imagePath: "./wallet Images/Dharma.jpeg" },
  {
    id: 41,
    name: "HaloDefi Wallet",
    imagePath: "./wallet Images/HaloDefi Wallet.jpeg",
  },
  {
    id: 8,
    name: "AToken Wallet",
    imagePath: "./wallet Images/ATOKEN Wallet.jpeg",
  },
  { id: 30, name: "Digitex", imagePath: "./wallet Images/digitex.jpg" },
  { id: 89, name: "ViaWallet", imagePath: "./wallet Images/ViaWallet.jpeg" },
  { id: 51, name: "Ledger", imagePath: "./wallet Images/ledger.jpg" },
  {
    id: 46,
    name: "Infinity Wallet",
    imagePath: "./wallet Images/infinity wallet.jpeg",
  },
  {
    id: 38,
    name: "Gnosis Safe Multisig",
    imagePath: "./wallet Images/Gnosis Safe Multisig.jpeg",
  },
  { id: 19, name: "Coinbase", imagePath: "./wallet Images/coinbase-2.jpg" },
  { id: 10, name: "Authereum", imagePath: "./wallet Images/Authereum.jpeg" },
  {
    id: 53,
    name: "MathWallet",
    imagePath: "./wallet Images/Math wallet.jpeg",
  },
  {
    id: 27,
    name: "Defiant Wallet",
    imagePath: "./wallet Images/Defiant Wallet.jpeg",
  },
  { id: 12, name: "BitKeep", imagePath: "./wallet Images/BitKeep.jpeg" },
  { id: 91, name: "Wallet.io", imagePath: "./wallet Images/wallet.io.jpeg" },
  {
    id: 93,
    name: "XinFin XDC Network",
    imagePath: "./wallet Images/Xin Fin SDC Network.jpeg",
  },
  { id: 94, name: "ZelCore", imagePath: "./wallet Images/ZelCOre.jpeg" },
  { id: 63, name: "PlasmaPay", imagePath: "./wallet Images/PlasmaPay.jpeg" },
  {
    id: 71,
    name: "Sovryn Wallet",
    imagePath: "./wallet Images/Sovryn Wallet.jpg",
  },
  { id: 78, name: "Tokenary", imagePath: "./wallet Images/tokenary.jpeg" },
  { id: 70, name: "Skale", imagePath: "./wallet Images/skale.jpg" },
  {
    id: 3,
    name: "Aktionariat",
    imagePath: "./wallet Images/Aktionariat.jpeg",
  },
  { id: 73, name: "Spatium", imagePath: "./wallet Images/Spatium.jpeg" },
  { id: 74, name: "Squarelink", imagePath: "./wallet Images/squarelink.png" },
  { id: 39, name: "GridPlus", imagePath: "./wallet Images/gridplus.jpeg" },
  { id: 13, name: "BitPay", imagePath: "./wallet Images/bitpay.jpeg" },
  { id: 82, name: "Trezor", imagePath: "./wallet Images/trezor.png" },
  {
    id: 72,
    name: "Spark Point",
    imagePath: "./wallet Images/Spark Point.jpeg",
  },
  { id: 35, name: "Exodus", imagePath: "./wallet Images/exodus.jpg" },
  {
    id: 16,
    name: "Celo Wallet",
    imagePath: "./wallet Images/Celo Wallet.jpeg",
  },
  { id: 56, name: "MYKEY", imagePath: "./wallet Images/MY Key.jpeg" },
  {
    id: 61,
    name: "PEAKDEFI Wallet",
    imagePath: "./wallet Images/PEAKDEFI WALLET.jpeg",
  },
  {
    id: 26,
    name: "D'CENT Wallet",
    imagePath: "./wallet Images/D'Cent Wallet.jpeg",
  },
  { id: 57, name: "Nash", imagePath: "./wallet Images/nash.jpeg" },
  { id: 33, name: "Eidoo", imagePath: "./wallet Images/eidoo.jpeg" },
  { id: 79, name: "Tomo Chain", imagePath: "./wallet Images/tomo.jpg" },
  { id: 4, name: "Alice", imagePath: "./wallet Images/alice.jpeg" },
  { id: 81, name: "Torus", imagePath: "./wallet Images/Torus.jpeg" },
  {
    id: 47,
    name: "Jade Wallet",
    imagePath: "./wallet Images/Jade Wallet.jpeg",
  },
  {
    id: 69,
    name: "Scatter Wallet",
    imagePath: "./wallet Images/scatter.png",
  },
  {
    id: 52,
    name: "Loop Ring Wallet",
    imagePath: "./wallet Images/Loop Ring Wallet.jpeg",
  },
  { id: 88, name: "Vechain", imagePath: "./wallet Images/vechain.png" },
  { id: 34, name: "Ellipal", imagePath: "./wallet Images/Ellipal.jpeg" },
  {
    id: 75,
    name: "Talken Wallet",
    imagePath: "./wallet Images/Talken Wallet.jpeg",
  },
  { id: 59, name: "ONTO", imagePath: "./wallet Images/onto.jpeg" },
  {
    id: 1,
    name: "1inch Wallet",
    imagePath: "./wallet Images/1Inchwallet.jpeg",
  },
  {
    id: 32,
    name: "EasyPocket",
    imagePath: "./wallet Images/easy pocket.jpeg",
  },
  { id: 65, name: "QuiverX", imagePath: "./wallet Images/Quiever X.jpeg" },
  {
    id: 24,
    name: "Crypto.com | DeFi Wallet",
    imagePath: "./wallet Images/crypto_com defi wallet.jpeg",
  },
  { id: 62, name: "Pillar", imagePath: "./wallet Images/pilar.jpeg" },
  { id: 21, name: "Coinomi", imagePath: "./wallet Images/coinomi.jpeg" },
  { id: 999, name: "Others", imagePath: "./wallet Images/others.png" },
];

export default wallet;

export type WalletType = (typeof wallet)[number];
